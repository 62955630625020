<template>
  <div class="p-fluid">
    <div class="p-col-12 p-lg-12">
      <div class="card card-w-title">
        <div class="manager-title">{{ title }}</div>
        <!--<div class="note">(*) Nội dung không được bỏ trống</div>-->
        <formEdit
          :key="componentKey"
          :mode="mode"
          @back="backToList"
          @save="saveData"
          :model="models"/>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import Config from '../../../config/index'
import DataServices from '@/core/DataServices'
import formEdit from './confirm_order_add'
import ApiRepository from "@/core/ApiRepository";
//import firebase from "../../../../firebase.json";
export default {
  components: {
    formEdit
  },
  data() {
    return {
      validationErrors: [],
      title: "Chi Tiết Đơn Hàng",
      mode: 'detail',
      models: {},
      images: [],
      oldImages: [],
      componentKey: 0,
      page_transaction:null
    }
  },
  async mounted () {
    console.log("this.$route.name:",this.$route.name);
    this.mode = this.$route.name === 'editOrder' ? 'edit' : this.mode;
    this.title = this.mode === 'edit' ? 'Xác nhận nhận hàng' : this.title;
    var type =1
    if (!this.$commonFuction.isEmpty(this.$route.query.type)){
      type = parseInt(this.$route.query.type)
    }
    if (type==2){
      this.title = 'Lý do trả hàng';
    }
    if (this.$route.params.id !== undefined) await this.getData(this.$route.params.id);
    if (!this.$commonFuction.isEmpty(this.$route.query)){
      if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)){
        this.page_transaction = this.$route.query.page_transaction;
      }
    }
    //check quyền
    var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_ORDER);
    console.log("arr_rolearr_role:",arr_role)
    if (type==2){
      if (arr_role.indexOf(this.$constants.ROLE.REASON_RETURN) < 0){
        this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
      }
    }else {
      if (arr_role.indexOf(this.$constants.ROLE.CONFIRMATION_RECEIPT) < 0){
        this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
      }
    }
  },
  methods: {
    backToList() {
      //window.location.href = '/admin/list-order/view/'+this.$route.params.id
      this.$router.push({ path: '/admin/list-order/view/'+this.$route.params.id});
    },
    convertPhone(numberPhone) {
      var phone = numberPhone
      if (numberPhone[0] === '0') {
        phone = phone.substring(1);
      }
      phone = phone.replace(' ', '')
      phone = '+84' + phone
      return phone
    },
    async saveData(params) {
     /* var test_acc =1;
      if (test_acc == 1){
        console.log("await this.validateData():3333",params);
        return false;
      }
*/
      try {
        var dataRequest = params
        const headers = {
          "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
        };
        var list_action_detail = await ApiRepository.post('/_api/order/edit_order', dataRequest, {headers});
        console.log("list_action_detail:",list_action_detail)
        this.$toast.add({ severity: 'success', summary: 'Thành công', detail: 'Lưu dữ liệu thành công', life: 3000 })
        //this.$router.push({ path: '/admin/list-order/view/'+params.id ,query: { key_tmp: (new Date()).getTime() } })
        window.location.href = '/admin/list-order/view/'+params.id
        //this.list_action_detail = (list_action_detail["data"]["data"]["list_action_detail"]) ? list_action_detail["data"]["data"]["list_action_detail"]:[]
        //console.log("list_action_detail:",list_action_detail["data"]["data"]["list_action_detail"])
      } catch (err) {
        console.log("saveData err:",err);
      }
      /*if (params !== undefined && Object.entries(params).length > 0) {

        var objects = {
          'phone': params.user.phone,
          'fullname': params.user.fullname,
          'plain_fullname': this.$commonFuction.SkipVN(params.user.fullname),
          'email': params.user.email,
          'is_admin': true,
          'role':'admin_child'
        }
        var group_user = {
          'group_id': params.user.group_id,
        }
        var that = this;
        if (this.mode === 'add') {
          
          var dataInsert = this.$commonFuction.convertJsonObject(objects);
          let accountsInsert = {
            'account': params.user.email,
            'type': 'EMAIL',
            'role': 'admin_child',
            'password' : this.$commonFuction.hashPasswordAdmin(params.user.password),
          }
          dataInsert.accounts = {data: this.$commonFuction.convertJsonObject(accountsInsert)};
          dataInsert.group_users = {data: this.$commonFuction.convertJsonObject(group_user)};

          await that.$apollo
              .mutate({
                //mutation: gql(queryUsers),
                mutation: DataServices.insertData('users'),
                variables: {
                  objects: dataInsert
                }
              })
              .then( (res)  => {
                console.log('Thành công');
                console.log(res);//
                this.$commonFuction.is_loading(false);
                that.$toast.add({severity: 'success', summary: 'Thông báo', detail: 'Thêm thành công', life: 3000});
                this.$router.push({path: '/admin/list-view_admin'});
              }).catch(async error => {
                console.log('error', error);
                that.$toast.add({
                  severity: 'error',
                  summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                  detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                  life: 3000
                });
                this.$commonFuction.is_loading(false);
                // this.summitEvent = false;
              });
        } 
        else  {
          //trường hợp ko chỉnh sửa email
          var uid = params.user.user_id
          var accountUpdate = {
            'account': params.user.email,
            // 'account': params.user.email,
          }
          if(params.user.password){
            accountUpdate.password = this.$commonFuction.hashPasswordAdmin(params.user.password)
          }
          var queryUpdate = `
                  mutation update_users_multil($users_object: users_set_input,$accounts_object: accounts_set_input,$group_user_object: group_user_set_input) {
                    update_users(where: { id: {_eq: "${uid}"}}, _set: $users_object) {
                      affected_rows
                      returning {
                        id
                      }
                    }
                    update_accounts(where: { uid: {_eq: "${uid}"}}, _set: $accounts_object) {
                      affected_rows
                      returning {
                        id
                      }
                    }
                    update_group_user(where: { uid: {_eq: "${uid}"}}, _set: $group_user_object) {
                      affected_rows
                      returning {
                        uid
                      }
                    }

                  }
            `;

          let variables = {
            users_object: objects,
            accounts_object: accountUpdate,
            group_user_object: group_user,
          };
          that.$apollo.mutate({
            mutation: gql(queryUpdate),
            variables: variables
          }).then(async (res) => {
            console.log(res)
            that.$commonFuction.is_loading(false);
            var router_back = {path: '/admin/list-view_admin'};
            if (!that.$commonFuction.isEmpty(router_back)){
              router_back.query = { page_transaction: that.page_transaction.toString() };
            }
            that.$router.push(router_back);
            that.$toast.add({ severity: 'success', summary: 'Thành công', detail: 'Lưu dữ liệu thành công', life: 3000 })
          })
        }
      }*/
    },
    async saveListCategory(userID,list,type="add") {
      if(type == "add"){
        var list_add = []
        for (let i = 0; i < list.length; i++) {
          const el = list[i];
          list_add.push({uid :userID,category_id : el.id})
        }
        if(list_add.length > 0){
          var query_insert = DataServices.insertData("users_category")
          var variables_insert = {
            objects: list_add
          }
          await this.$apollo.mutate({
            mutation: query_insert,
            variables: variables_insert
          }).then(() => {
          })
        }
      }else if(type == "del"){
        let query = `mutation Mymutation($_in: [bigint!] = "", $_eq: uuid = "") {
          delete_users_category(where: {_and: {category_id: {_in: $_in}, uid: {_eq: $_eq}}}) {
            affected_rows
          }
        }`;
        var list_del = []
        for (let i = 0; i < list.length; i++) {
          const element = list[i];
          list_del.push(element.id)
        }
        if(list_del.length > 0){
          let variables_del = {
            _in : list_del,
            _eq : userID,
          }
          await this.$apollo.mutate({
            mutation: gql(query),
            variables: variables_del
          }).then(() => {
          })
        }
      }
    },
    async uploadImagesOld(userID) {
      var s3client = Config.s3Client('user')
      if (this.images && userID) {
        //xoá avatar
        if (this.oldImages.length > 0) await this.deleteAvatar(s3client);

        this.$store.commit('setLoading', true);
        var objects = null;
        for (let index = 0; index < this.images.length; index++) {
          const img = this.images[index];
          let file = img.source
          if (file !== '' && img.file !== '') {
            let fileName = img.name;
            let random = Math.random().toString().slice(2);
            fileName = `${random}_${fileName}`
            await s3client.uploadFile(file, fileName).then((data) => {
              objects = data.location;
            });
          }
        }
        // console.log(objects);
        if (objects !== null) {
          this.$apollo.mutate({
            mutation: DataServices.updateData('users'),
            variables: {
              'data_update': {
                'avatar': objects
              },
              'where_key': {
                'id': {'_eq': userID}
              }
            }
          }).then(() => {
            // console.log('UPDATE AVATAR THANH CONG')
          }).catch((error) => {
            console.error('ERROR: ', error)
          })
        }
      }
    },
    async deleteAvatar(s3client) {
      for (let i = 0; i < this.oldImages.length; i++) {
        let fileName = this.oldImages[i].name
        await s3client.deleteFile(fileName)
      }
    },
    async getData(id) {
      var {data} = await this.$apollo.query({
        query: DataServices.getList('order'),
        variables: {
          where_key: {
            id: {'_eq': id}
          }
        },
        fetchPolicy: "network-only"
      })
      console.log("datadata:",data);
      var model = data[Object.keys(data)];
      if (model.length > 0) {
        this.models = model[0];
      }
      this.componentKey++;
    },

  },
  apollo: {
    // categories: {
    //   query() {
    //     return DataServices.getList('category', {"fields" : "id name"});
    //   },
    //   update(data) {
    //     return data[Object.keys(data)];
    //   },
    //   fetchPolicy: "network-only"
    // },
    // users: {
    //   query() {
    //     return DataServices.getList('users', {"fields" : "id fullname phone"});
    //   },
    //   update(data) {
    //     return data[Object.keys(data)];
    //   },
    //   fetchPolicy: "network-only"
    // }
  }
}
</script>

<style lang="scss">
.p-inputnumber-buttons-stacked {
  .p-inputnumber-button-group {
    .p-button.p-button-icon-only { padding: 0 !important; }
  }
}
.note{ letter-spacing: 0px; color: #707070; margin-bottom: 24px; padding-top: 10px;}
.p-formgrid { margin: 0;
  .p-panel-header {color: #000000; font-size: 18px; font-weight: bold; background: #EEEEEE;}
  .p-card-field{ padding: 24px;
    .p-field{
      .p-col-fixed { width: 300px; display: inline-block !important; margin: 0;}
      .p-col-width{width:500px !important; border-radius: 5px;
        &.disabled{background: #EEEEEE; border: 0 none; padding: 8px 10px;}
        .file-select{margin: 0;
          input[type="file"]{display: none;}
          .select-button{display: inline-block; cursor: pointer; background: transparent; border-radius: 5px;  color: #000000; font-size: 13px; padding: 3px 16px;  border: 1px solid #B4B4B4;}
        }
        &.pac-target-input{ height: 80px;  word-break: break-all; overflow-wrap: break-word;}
      }
      .box-images{ margin-top: 10px; position: relative;
        .item-img{height: 150px;  margin-right: 8px; margin-bottom: 8px; display: inline-flex; position: relative; border: 0.5px solid #ccc;
          &.p-col-5{width: 18%;;}
          img{max-width: 100%; height: auto;margin: 0 auto; object-fit: contain;}
          .button-clear{width: 18px; height: 18px; background: #F5564E; float: right; cursor: pointer; position: absolute; right: 0px;
            img{vertical-align: top;}
          }
        }
      }
      .p-invalid{display: block;}
    }
  }
  .fixed-bottom-toolbar{background: transparent; border: 0 none; margin-top: 22px; border-top: 1px solid #B4B4B4; padding: 0; padding-top: 30px;
    .p-button{width: auto; margin-right: 8px;
      &:last-child{margin-right: 0;}
    }
  }
}
</style>
<style>
.list-group-v {
  /* display: -webkit-box;
   display: -ms-flexbox;*/
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  /*-ms-flex-direction: column;*/
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}
.list-group-item-v:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}
.list-group-item-v {
  position: relative;
  display: block;
  padding: .75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.125) ;
  height: 50px;
}
.box-check-box{
  position: relative;
}
.box-check-box label{
  position: absolute;
  left: 0px;
  top: 2px;
}
.box-check-box .check-box-day{
  position: absolute;
  right: 0px;
  top: 2px;
}
</style>